import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import SEO from '@/components/SEO';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
  BreadCrumbsWrapper,
} from '@/styles/pages/perfil-de-carga';

import HeroSection from '@/components/Pages/PerfilDeCarga/HeroSection';
import SectionTop from '@/components/Pages/PerfilDeCarga/SectionTop';
import SectionBottom from '@/components/Pages/PerfilDeCarga/SectionBottom';
import BottomText from '@/components/Pages/PerfilDeCarga/BottomText';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'A Generoso' },
  { name: 'Perfil de carga' },
];

const PerfilDeCargaPage = (): JSX.Element => {
  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "perfil-de-carga" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Perfil de carga"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>
            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>
            <SectionWrapper>
              <SectionTop />
            </SectionWrapper>
            <SectionWrapper>
              <SectionBottom />
            </SectionWrapper>
            <SectionWrapper>
              <BottomText />
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default PerfilDeCargaPage;
