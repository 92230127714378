import React from 'react';
import { SectionBottom, SectionBottomList } from './styles';

const SectionBottomComponent = (): JSX.Element => {
  return (
    <SectionBottom>
      <h2>Materiais não transportados</h2>

      <SectionBottomList>
        <ul>
          <li>Gelo / flores, plantas vivas</li>
          <li>Produtos sujeitos a Controle de Temperatura (Isotérmicos);</li>
          <li>
            Ações, apólices, bilhetes de loterias e todo e qualquer papel com
            valor facial (cheques, letras de câmbio, dinheiro em espécie);
          </li>
          <li>Cargas vivas</li>
          <li>
            Antiguidades e todo e qualquer tipo de objetos de artes e coleções;
          </li>
          <li>Armas, munições, explosivos e similares (CLASSE DE RISCO 1);</li>
          <li>
            Artesanatos de vidros, louças, espelhos e pedras de mármore ou
            similar;
          </li>
          <li>
            Automóveis, motocicletas, motores e ou similares sem embalagens ou
            com embalagens inadequadas.
          </li>
          <li>
            Todo e qualquer tipo de impressos, que caracterizam remessas via
            correios. (cartas, selos, estampilhas, cheques, contas, etc);
          </li>
        </ul>

        <ul>
          <li>
            Materiais Biológicos (infecto contagiosos), mesmo em embalagem
            adequada;
          </li>
          <li>Produtos deterioráveis e ou perecíveis;</li>
          <li>Metais e pedras preciosas, semi-jóias e jóias;</li>
          <li>Eletrodomésticos sem embalagem;</li>
          <li>
            Vacinas e produtos similares com temperatura controlada
            (Termolábeis);
          </li>
          <li>Vidros planos e ou côncavos, sem embalagens;</li>
          <li>Materiais Radioativos (CLASSE 7);</li>
          <li>Aparelhos de Telefones Celulares e acessórios para os mesmos;</li>
          <li>Cigarros;</li>
          <li>
            Escapamentos para veículos automotores em grande quantidades sem
            embalagens adequadas;
          </li>
          <li>Móveis, Mudanças parciais e ou completas de qualquer espécie.</li>
        </ul>
      </SectionBottomList>
    </SectionBottom>
  );
};

export default SectionBottomComponent;
