import styled from 'styled-components';

export const BottomText = styled.div`
  width: 100%;
  max-width: 796px;

  margin: 32px 0;

  text-align: center;

  font-size: 16px;
  font-weight: 400;

  @media (max-width: 1440px) {
    margin: 32px 29px;
  }

  @media (max-width: 500px) {
    display: none;
  }
`;
