import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionTop = styled.section`
  width: 100%;
  max-width: 1147px;
  display: flex;
  justify-content: space-between;

  height: 100%;

  margin-top: 50px;

  @media (max-width: 1440px) {
    margin-left: 29px;
    margin-right: 29px;
  }

  @media (max-width: 1180px) {
    flex-direction: column;

    align-items: center;
  }

  @media (max-width: 500px) {
    margin-top: 32px;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const SectionTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 561.25px;
  background-color: ${colors.gray100};

  height: 324px;

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  box-sizing: border-box;

  padding-left: 57px;

  h2 {
    color: ${colors.blue400};
    font-size: 32px;
    font-weight: 700;

    line-height: 48px;
  }

  p {
    color: ${colors.blue300};
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;

    max-width: 334px;

    margin-top: 12px;
  }

  a {
    background-color: ${colors.blue400};

    outline: none;

    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;

    max-width: 122px;

    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 25px;

    img {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 500px) {
    height: 248px;

    padding-left: 40px;

    a {
      margin-top: 16px;
    }
  }

  @media (max-width: 499px) {
    padding-left: 30px;
    h2 {
      font-size: 24px;
      line-height: 40px;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      line-height: 27px;
      max-width: 250px;
    }
  }
`;
export const SectionTopRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 561.25px;
  background-color: ${colors.gray100};

  height: 324px;

  border: 1px solid ${colors.blue200};
  border-radius: 8.4px;

  box-sizing: border-box;

  padding-left: 57px;

  h2 {
    color: ${colors.blue400};
    font-size: 32px;
    font-weight: 700;

    line-height: 40px;
  }

  p {
    color: ${colors.blue300};
    font-size: 24px;
    font-weight: 500;
    line-height: 35px;

    margin-top: 12px;

    max-width: 414px;
  }

  a {
    background-color: ${colors.blue400};

    outline: none;

    border: none;

    border-radius: 8.4px;
    height: 40px;

    width: 100%;

    max-width: 122px;

    color: white;

    font-size: 16px;
    font-weight: 500;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 25px;

    img {
      margin-left: 10px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 1180px) {
    margin-top: 50px;
    padding-left: 40px;
  }

  @media (max-width: 600px) {
    height: 338px;

    h2 {
      max-width: 220px;
    }

    p {
      max-width: 339px;
      margin-top: 24px;
    }

    a {
      margin-top: 16px;
    }
  }

  @media (max-width: 500px) {
    margin-top: 32px;
  }

  @media (max-width: 499px) {
    margin-top: 16px;
    padding-left: 30px;

    height: 280px;
    h2 {
      font-size: 24px;
      line-height: 40px;
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      line-height: 27px;
      max-width: 250px;
    }
  }
`;
