import React from 'react';
import { HeroSection } from './styles';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <h2>Perfil de carga</h2>
    </HeroSection>
  );
};

export default HeroSectionComponent;
