import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const SectionBottom = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1145.5px;
  background-color: ${colors.gray100};

  border: 1px solid ${colors.blue200};

  border-radius: 8.4px;

  box-sizing: border-box;

  padding-top: 64px;
  padding-bottom: 50px;

  margin-top: 30px;

  h2 {
    font-size: 35px;
    font-weight: 700;
    color: ${colors.red};
  }

  @media (max-width: 1440px) {
    margin: 0 29px;
    margin-top: 50px;
  }

  @media (max-width: 974px) {
    align-items: flex-start;
    padding-left: 35px;
  }

  @media (max-width: 500px) {
    margin: 32px 16px;
  }

  @media (max-width: 499px) {
    padding: 17px 20px;
    h2 {
      font-size: 24px;
      line-height: 40px;
    }
  }
`;

export const SectionBottomList = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: center;

  width: 100%;

  height: fit-content;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: fit-content;

    width: 100%;
    max-width: 425px;

    margin-top: 57px;

    li {
      font-size: 16px;
      font-weight: 500;
      margin-top: 32px;

      color: ${colors.gray600};

      &:first-child {
        margin-top: 0;
      }
    }

    li::before {
      content: '•';
      color: red;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }

    & + ul {
      margin-left: 50px;
    }
  }

  @media (max-width: 974px) {
    flex-direction: column;

    ul {
      margin-top: 16px;

      & + ul {
        margin-top: 32px;
        margin-left: 0;
      }
    }

    li {
      max-width: 334px;
      margin-left: 16px;
    }
  }

  @media (max-width: 499px) {
    ul {
      margin-top: 8px;

      li {
        font-size: 12px;
        margin-top: 8px;
        margin-left: 12px;
      }

      & + ul {
        margin-top: 8px;
      }
    }
  }
`;
