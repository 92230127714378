import React from 'react';
import { BottomText } from './styles';

const BottomTextComponent = (): JSX.Element => {
  return (
    <BottomText>
      Em caso de transporte desses produtos sem o conhecimento da transportadora
      qualquer ocorrência não estará coberta pela seguradora.
    </BottomText>
  );
};

export default BottomTextComponent;
