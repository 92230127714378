import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  width: 100%;
  max-width: 1380px;

  height: fit-content;
  min-height: 300px;

  background-color: ${colors.blue100};

  display: flex;
  align-items: center;

  padding-left: 29px;

  box-sizing: border-box;

  h2 {
    font-size: 32px;
    font-weight: 400;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
  }
`;
