import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { SectionTop, SectionTopLeft, SectionTopRight } from './styles';

import IconDownload from '@/images/icons/icon-download.svg';

const SectionTopComponent = (): JSX.Element => {
  const { directus } = useStaticQuery(query);

  const listaPdf = directus.arquivos;

  return (
    <SectionTop>
      <SectionTopLeft>
        <h2>Difícil entrega</h2>
        <p>Veja aqui os CEPs que possuem difícil acesso.</p>
        <a
          href={listaPdf.find((p: any) => p.name === 'Difícil entrega').link}
          target="_blank"
          rel="noreferrer"
        >
          Baixar
          <img src={IconDownload} alt="Ícone de download" />
        </a>
      </SectionTopLeft>
      <SectionTopRight>
        <h2>Clientes que possuem TDE</h2>
        <p>Esta lista pode sofrer alterações sem aviso prévio.</p>
        <a
          href={
            listaPdf.find((p: any) => p.name === 'Clientes que possuem TDE')
              .link
          }
          target="_blank"
          rel="noreferrer"
        >
          Baixar
          <img src={IconDownload} alt="Ícone de download" />
        </a>
      </SectionTopRight>
    </SectionTop>
  );
};

export default SectionTopComponent;

const query = graphql`
  query {
    directus {
      arquivos {
        name
        link
      }
    }
  }
`;
